import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import PasswordController from '@stimulus-components/password-visibility'
import Notification from "@stimulus-components/notification"
import ScrollTo from "@stimulus-components/scroll-to"
import Dropdown from "@stimulus-components/dropdown"

const application = Application.start()

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

application.register("password", PasswordController)
application.register("notification", Notification)
application.register("scroll-to", ScrollTo)
application.register("dropdown", Dropdown)
