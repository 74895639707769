import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'price',
    'frequency',
    'monthlyButton',
    'annualButton'
  ]

  static values = {
    monthlyPrice: String,
    annualPrice: String,
    perMonth: String,
    perYear: String
  }

  initialize () {
    this.activeClasses = 'bg-white border-gray-200 shadow-sm'
    this.disableClasses = 'border border-transparent'
  }

  switch (e) {
    e.preventDefault()

    const isMonthly = e.currentTarget.dataset.value === 'monthly'

    this.priceTarget.innerHTML = isMonthly ? this.monthlyPriceValue : this.annualPriceValue
    this.frequencyTarget.innerHTML = isMonthly ? this.perMonthValue : this.perYearValue

    this.activeClasses.split(' ').forEach(klass => {
      this.monthlyButtonTarget.classList.toggle(klass, isMonthly)
      this.annualButtonTarget.classList.toggle(klass, !isMonthly)
    })

    this.disableClasses.split(' ').forEach(klass => {
      this.monthlyButtonTarget.classList.toggle(klass, !isMonthly)
      this.annualButtonTarget.classList.toggle(klass, isMonthly)
    })
  }
}
