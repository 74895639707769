import Lightbox from "@stimulus-components/lightbox"
import "lightgallery/css/lightgallery.css"

export default class extends Lightbox {
  get defaultOptions () {
    return {
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      download: false
    }
  }
}
